<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Nazorg - Wonen</h1>
        <div class="row">
          <p>
            Onderstaand dashboard geeft informatie over Wonen als onderdeel van de levenspadfase Nazorg. Dit overzicht focust zich met name op de woonsituatie rondom het overlijden van de persoon met dementie. Hierin wordt informatie gegeven over de plaats van overlijden, de tijd tussen opname en overlijden en over mensen met dementie overleden voor opname. Het overzicht wordt gegeven met zowel landelijke als regionale cijfers op het niveau van zorgkantoorregio’s en dementienetwerken. Dit overzicht gaat over gegevens uit de jaren 2017-2022.
          </p>
        </div>
      </div>
    </div>

       <div class="row" v-if="selectedLevel!=='landelijk'">
        <div class="col-sm-12 down">
          <card :cardId="`Plaats van overlijden in ${selectedYear}`" :hovertext="'Data plaats van overlijden: plaats van overlijden elders en onbekend worden ook onder thuis gerapporteerd. Categorieën kunnen soms niet gerapporteerd worden i.v.m. herleidbaarheid. Bron: overlijdensstatistieken CBS.'" 
            :x="zorgkantoorregionaam_plaatsoverlijden" :y="percentage_plaatsoverlijden.map(value => `${value}%`)"
            :xtitle="'Region naam'" :ytitle="'Percentage'"
            :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 1500">
            <template slot="card-header">Plaats van overlijden in {{selectedYear}}</template>
            <div slot="card-body">
              Het aantal overleden mensen per regio ten opzichte van het totaal aantal mensen met dementie. In alle regio’s overleden de meeste mensen met dementie in een zorginstelling. 
              <stacked-bar-chart-colors v-if="load.graphOne"
                :ytitle="'% op plek overleden'"
                :names="['Thuis', 'Zorginstelling']"
                :x="[zorgkantoorregionaam_t, zorgkantoorregionaam_z]"
                :y="[thuis, zorginstelling]"
                :ticksuffix="'%'"
                />
            </div>
          </card>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="`Tijd tussen opname en overlijden in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'" 
            :x="zorgkantoorregionaam_opnametotoverlijden" :y="dag_opnametotoverlijden" :z="percentage_opnametotoverlijden.map(value => `${value}%`)"
            :xtitle="'Region naam'" :ytitle="'Dag'" :ztitle="'Percentage'"
            :tableHeight="selectedLevel === 'dementienetwerk' ? 4000 : 1500">
            <template slot="card-header">Tijd tussen opname en overlijden in {{selectedYear}}</template>
            <div slot="card-body">
             In onderstaand diagram wordt de tijd tussen opname en overlijden weergeven. Er worden verschillende tijdsgroepen gebruikt. Deze informatie is alleen op zorgkantoor beschikbaar. De verdeling van mensen met dementie over die groepen blijft redelijk constant over de jaren heen. Wel zijn er verschillen te zien tussen zorgkantoren. Zo is het opvallend dat in zorgkantoorregio Zuid-Limburg de groep mensen met dementie die overlijdt binnen 90 dagen na opname groter is dan alle andere regio's en het percentage dat thuis overlijdt juist kleiner is dan alle andere zorgkantoorregio's (zie grafiek ‘Plaats van Overlijden’ hierboven).
                <stacked-bar-chart-colors v-if="load.graphTwo" 
                :names="['0-90 dagen', '91-180 dagen', '181-365 dagen', '366-730 dagen','731+ dagen/niet overleden']"
                :ytitle="'% aantal dagen'"
                :x="[zorgkantoorregionaam_90, zorgkantoorregionaam_180, zorgkantoorregionaam_365, zorgkantoorregionaam_730, zorgkantoorregionaam_731n]"
                :y="[dagen_90, dagen_180, dagen_365, dagen_730, dagen_731n]"
                :ticksuffix="'%'"
                  />
            </div>
          </card>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='dementienetwerk'">
        <div class="col-sm-12 down">
          <card-small :cardId="'Tijd tussen opname en overlijden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
            <template slot="card-header">Tijd tussen opname en overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>
<!--       <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="'nazorg-wonen-3'" v-if="selectedLevel==='zorgkantoor'" :hovertext="'Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. De noemer is iedereen met een nieuwe indicatie in jaar x zonder opname. De teller is iedereen met een nieuwe indicatie in jaar x, zonder opname, overleden binnen 6 maanden na indicatie. De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
            <template slot="card-header">Overleden 6 maanden voor opname in {{selectedYear}}</template>
            <div slot="card-body">
                <p class="graphtext">In deze grafiek wordt het percentage mensen met dementie weergegeven dat overlijdt in de 6 maanden voor opname. Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor zich verhoudt tot het landelijk gemiddelde. Deze informatie is alleen op zorgkantoor beschikbaar. </p>
                  <stacked-bar-chart-colors v-if="load.graphThree"
                  :ytitle="'% overleden binnen 6 maanden'"
                  :names="['%Overleden voor opname']"
                  :x="[zorgkantoorregionaamovo]"
                  :y="[perc_ovo]"
                :ticksuffix="'%'"
                  :showlegend="false"/>
            </div>
          </card>
          <card-small :cardId="'nazorg-wonen-3-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. De noemer is iedereen met een nieuwe indicatie in jaar x zonder opname. De teller is iedereen met een nieuwe indicatie in jaar x, zonder opname, overleden binnen 6 maanden na indicatie. De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
            <template slot="card-header">Overleden 6 maanden voor opname - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div> -->

      <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Plaats van overlijden'" :hovertext="'Data plaats van overlijden: plaats van overlijden elders en onbekend worden ook onder thuis gerapporteerd. Categorieën kunnen soms niet gerapporteerd worden i.v.m. herleidbaarheid. Bron: overlijdensstatistieken CBS.'" 
              :x="jaar_plaatsoverlijden" :y="percentage_plaatsoverlijden.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Percentage'"
              :tableHeight="450">
                <template slot="card-header">Plaats van overlijden</template>
                <div slot="card-body">
                  Onderstaande grafiek toont het landelijke niveau van het aantal overledenen ten opzichte van het totaal aantal mensen met dementie.
                    <stacked-bar-chart-colors-multi v-if="load.graphFour" :tickangle="0"
                    :isLandelijk='false'
                    :height= "360"
                    :name2="'ThuisElders'"
                    :x2="zorgkantoorregionaam_t"
                    :xx2="jaar_te"
                    :y2="thuis"
                    :name1="'Zorginstelling'"
                    :x1="zorgkantoorregionaam_z"
                    :xx1="jaar_zi"
                    :y1="zorginstelling" />
                  </div>
                </card>
              </div>
            </div>

            <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Tijd tussen opname en overlijden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata.'"
              :x="jaar_opnametotoverlijden" :y="dag_opnametotoverlijden" :z="percentage_opnametotoverlijden.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Dag'" :ztitle="'Percentage'" :tableHeight="650">
                <template slot="card-header">Tijd tussen opname en overlijden</template>
                <div slot="card-body">
                  In onderstaand diagram wordt de tijd tussen opname en overlijden weergeven. Er worden verschillende tijdsgroepen gebruikt. Deze informatie is niet per dementienetwerk beschikbaar.
                    <stacked-bar-chart-colors-multi v-if="load.graphFour" :tickangle="0"
                    :isLandelijk='false'
                    :height= "360"
                    :name5="'731+ dagen/niet overleden'"
                    :x5="zorgkantoorregionaam_731n"
                    :xx5="jaar_731n"
                    :y5="dagen_731n_landelijk"
                    :name4="'366-730 dagen'"
                    :x4="zorgkantoorregionaam_730"
                    :xx4="jaar_730"
                    :y4="dagen_730_landelijk"
                    :name3="'181-365 dagen'"
                    :x3="zorgkantoorregionaam_365"
                    :xx3="jaar_365"
                    :y3="dagen_365_landelijk"
                    :name2="'91-180 dagen'"
                    :x2="zorgkantoorregionaam_180"
                    :xx2="jaar_180"
                    :y2="dagen_180_landelijk"
                    :name1="'0-90 dagen'"
                    :x1="zorgkantoorregionaam_90"
                    :xx1="jaar_90"
                    :y1="dagen_90_landelijk" />
                  </div>
                </card>
              </div>
            </div>

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>

import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Nazorg - Wonen',
  },
  components: { Card, StackedBarChartColors, CardSmall, Footer, StackedBarChartColorsMulti},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_t: [],
      zorgkantoorregionaam_z: [],
      zorgkantoorregionaam_90: [],
      dagen_90: [],
      zorgkantoorregionaam_180: [],
      dagen_180: [],
      zorgkantoorregionaam_365: [],
      dagen_365: [],
      zorgkantoorregionaam_730: [],
      dagen_730: [],
      zorgkantoorregionaam_731n: [],
      dagen_731n: [],
      zorgkantoorregionaamovo: [],
      perc_ovo: [],
      thuis: [],
      zorginstelling: [],
      jaar_zi:[], 
      jaar_te:[],
      jaar_90:[], 
      jaar_180:[], 
      jaar_365:[], 
      jaar_730:[], 
      jaar_731n:[],
      dagen_90_landelijk: [],
      dagen_180_landelijk: [],
      dagen_365_landelijk: [],
      dagen_730_landelijk: [],
      dagen_731n_landelijk: [],
      zorgkantoorregionaam_plaatsoverlijden:[],
      zorgkantoorregionaam_opnametotoverlijden:[],
      percentage_plaatsoverlijden:[],
      percentage_opnametotoverlijden:[],
      dag_opnametotoverlijden:[],
      jaar_plaatsoverlijden:[],
      jaar_opnametotoverlijden:[],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false
      }

    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_plaatsoverlijden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_t = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => d.zorgkantoorregionaam)
            this.thuis = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => (d.percentage))
            this.zorgkantoorregionaam_z = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => d.zorgkantoorregionaam)
            this.zorginstelling = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => (d.percentage))

            this.zorgkantoorregionaam_plaatsoverlijden = response.data.map(d => d.zorgkantoorregionaam)
            this.percentage_plaatsoverlijden = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_opnametotoverlijden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => b.percentage-a.percentage)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.zorgkantoorregionaam_90 = response.data.filter(x => x.opname_tot_overlijden =="0-90 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_90 = response.data.filter(x => x.opname_tot_overlijden =="0-90 dagen").map(d => (d.percentage))
          this.zorgkantoorregionaam_180 = response.data.filter(x => x.opname_tot_overlijden =="91-180 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_180 = response.data.filter(x => x.opname_tot_overlijden =="91-180 dagen").map(d => (d.percentage))
          this.zorgkantoorregionaam_365 = response.data.filter(x => x.opname_tot_overlijden =="181-365 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_365 = response.data.filter(x => x.opname_tot_overlijden =="181-365 dagen").map(d => (d.percentage))
          this.zorgkantoorregionaam_730 = response.data.filter(x => x.opname_tot_overlijden =="366-730 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_730 = response.data.filter(x => x.opname_tot_overlijden =="366-730 dagen").map(d => (d.percentage))
          this.zorgkantoorregionaam_731n = response.data.filter(x => x.opname_tot_overlijden =="langer dan 731 dagen/niet overleden").map(d => d.zorgkantoorregionaam)
          this.dagen_731n = response.data.filter(x => x.opname_tot_overlijden =="langer dan 731 dagen/niet overleden").map(d => (d.percentage))

          this.zorgkantoorregionaam_opnametotoverlijden = response.data.map(d => d.zorgkantoorregionaam)
          this.percentage_opnametotoverlijden = response.data.map(d => (d.percentage).toFixed(1))
          this.dag_opnametotoverlijden = response.data.map(x => x.opname_tot_overlijden)

          this.load.graphTwo=true
        }
      ),
      this.$http.get(`/api/zorgkantoor_overledenvooropname/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => b.percentage_overleden_voor_opname-a.percentage_overleden_voor_opname)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.perc_ovo = response.data.map(d => (d.percentage_overleden_voor_opname))
          this.zorgkantoorregionaamovo = response.data.map(d => d.zorgkantoorregionaam)
          this.load.graphThree=true
        }
      )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_plaatsoverlijden/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.jaar_te = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => d.jaar)
            this.zorgkantoorregionaam_t = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => d.zorgkantoorregionaam)
            this.thuis = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => (d.percentage))
            this.jaar_zi = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => d.jaar)
            this.zorgkantoorregionaam_z = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => d.zorgkantoorregionaam)
            this.zorginstelling = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => (d.percentage))


            this.zorgkantoorregionaam_plaatsoverlijden = response.data.map(d => d.zorgkantoorregionaam)
            this.jaar_plaatsoverlijden = response.data.map(d => d.jaar)
            this.percentage_plaatsoverlijden = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_opnametotoverlijden/get_data/?landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => a.jaar-b.jaar)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.jaar_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => d.jaar)
          this.zorgkantoorregionaam_90 = response.data.filter(x => x.opname_tot_overlijden =="0-90 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_90_landelijk = response.data.filter(x => x.opname_tot_overlijden =="0-90 dagen").map(d => (d.percentage))
          this.jaar_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => d.jaar)
          this.zorgkantoorregionaam_180 = response.data.filter(x => x.opname_tot_overlijden =="91-180 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_180_landelijk = response.data.filter(x => x.opname_tot_overlijden =="91-180 dagen").map(d => (d.percentage))
          this.jaar_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => d.jaar)
          this.zorgkantoorregionaam_365 = response.data.filter(x => x.opname_tot_overlijden =="181-365 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_365_landelijk = response.data.filter(x => x.opname_tot_overlijden =="181-365 dagen").map(d => (d.percentage))
          this.jaar_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => d.jaar)
          this.zorgkantoorregionaam_730 = response.data.filter(x => x.opname_tot_overlijden =="366-730 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen_730_landelijk = response.data.filter(x => x.opname_tot_overlijden =="366-730 dagen").map(d => (d.percentage))
          this.jaar_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => d.jaar)
          this.zorgkantoorregionaam_731n = response.data.filter(x => x.opname_tot_overlijden =="langer dan 731 dagen/niet overleden").map(d => d.zorgkantoorregionaam)
          this.dagen_731n_landelijk = response.data.filter(x => x.opname_tot_overlijden =="langer dan 731 dagen/niet overleden").map(d => (d.percentage))

          this.zorgkantoorregionaam_opnametotoverlijden = response.data.map(d => d.zorgkantoorregionaam)
          this.percentage_opnametotoverlijden = response.data.map(d => (d.percentage).toFixed(1))
          this.dag_opnametotoverlijden = response.data.map(x => x.opname_tot_overlijden)
          this.jaar_opnametotoverlijden = response.data.map(d => d.jaar)

          this.load.graphFour=true
        }
      )
      }
      else {
        this.$http.get(`/api/dementienetwerk_plaatsoverlijden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_t = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => d.dementienetwerknaam)
            this.thuis = response.data.filter(x => x.plaatsoverlijden == "ThuisElders").map(d => (d.percentage))
            this.zorgkantoorregionaam_z = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => d.dementienetwerknaam)
            this.zorginstelling = response.data.filter(x => x.plaatsoverlijden == "Zorginstelling").map(d => (d.percentage))

            this.zorgkantoorregionaam_plaatsoverlijden = response.data.map(d => d.dementienetwerknaam)
            this.percentage_plaatsoverlijden = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphOne=true
          }
        )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
  },
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>